<template>
  <div>
    <b-row>
      <b-col cols="12" class="text-center text-light text-left mb-3 song-list">
        <b-row>
          <b-col cols="4" sm="3" md="3" lg="2" class="pl-sm-0 px-sm-0">
            <player
              v-if="isOwn(request_user_id, song.artist_id)"
              :image="song.song_image"
              :file="song.file"
              :id="song.id"
              :title="song.name"
              :username="song.artistname"
              :artist_id="song.artist_id"
              :queue="this.queue_songs"
            ></player>
            <player
              v-else
              :chipin_plus="song.chipin_plus"
              :locked="song.locked"
              :image="song.song_image"
              :file="song.file"
              :id="song.id"
              :title="song.song_name"
              :username="song.artistname"
              :artist_id="song.artist_id"
              :indexed="index"
              :queue="this.queue_songs"
            ></player>
          </b-col>

          <b-col
            cols="5"
            sm="6"
            lg="7"
            class="text-left c-song-title pl-0 pl-lg-3 pr-0"
          >
            <p class="text-light mt-0 pb-0 mb-2 font-weight-light first-line">
              <img
                v-if="song.chipin_plus"
                src="../../../assets/chipin-plus-locked-light.svg"
                class="mr-2 mr-md-3 chipinplus-badge"
                style="margin-top: -8px;"
                alt="ChipIn+"
              />

              <b-button
                v-if="song.explicit"
                size="sm"
                variant="outline-light"
                class="text-uppercase explicit text-light py-0 mr-2 mr-md-3"
                style="margin-top:-7px;"
                >18</b-button
              >
              <router-link
                class="text-light song-title"
                :to="{
                  name: 'SongProfile',
                  params: { username: song.username, id: song.id },
                }"
                >{{ song.name }}
              </router-link>
            </p>

            <p class="text-light mt-0 pb-0 mb-2 font-weight-light second-line">
              <b-button
                v-if="song.retrak"
                pill
                size="sm"
                variant="warning"
                class="text-uppercase text-light py-0 mr-2 mr-md-3 pill-button"
                >RT</b-button
              >
              <b-button
                v-if="song.collaboration"
                pill
                size="sm"
                variant="warning"
                class="text-uppercase text-light py-0 mr-2 mr-md-3 pill-button"
                >CL</b-button
              >

              <router-link
                class="text-light font-weight-lighter artist"
                :to="{
                  name: 'ArtistUsername',
                  params: { id: song.username },
                }"
                >{{ song.username }}</router-link
              >
            </p>

            <p class="font-weight-lighter song-description">
              {{ song.description }}
            </p>
          </b-col>

          <b-col cols="3" class=" text-right pl-0 ">
            <b-row class="c-song-actions text-right">
              <b-col class="px-0">
                <SongActions
                  v-bind:song="song"
                  v-bind:index="index"
                  v-bind:go_to_artist="true"
                  v-bind:delete_type="'song'"
                ></SongActions>

                <b-button
                  v-if="this.liked"
                  variant="link"
                  class="float-right px-0 pt-0 pb-0"
                >
                  <img
                    src="../../../assets/heart-filled.svg"
                    @click="unlikeSong()"
                    class="align-top c-heart-btn"
                    height="25"
                    alt="Menu"
                  />
                </b-button>

                <b-button
                  v-if="!this.liked"
                  variant="link"
                  class="float-right px-0 pt-0 pb-0"
                >
                  <img
                    src="../../../assets/heart.svg"
                    @click="likeSong()"
                    class="align-top c-heart-btn"
                    height="25"
                    alt="Menu"
                  />
                </b-button>

                <p
                  v-if="song.listens > 0"
                  class="float-right pb-0 mb-0 d-none d-lg-inline-block font-weight-light c-song-listens pl-md-4 mr-lg-3"
                >
                  {{ song.listens }}
                </p>

                <p
                  class="float-right pb-0 mb-0 d-none d-lg-inline-block song-time-code font-weight-light c-song-listens mr-lg-3"
                >
                  {{ song.timecode }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="px-0">
                <b-row class="d-inline-block d-lg-none ">
                  <b-col>
                    <p
                      v-if="song.listens > 0"
                      class="float-right listens-mobile pb-0 mb-0 d-inline-block d-lg-none font-weight-light c-song-listens mr-lg-3"
                    >
                      {{ song.listens }}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-img
                      v-if="song.reward"
                      :src="
                        getImagePath(song.reward, { width: 46, height: 56 })
                      "
                      blank-color="#ccc"
                      class="img-fluid reward-image float-right text-right photo-border ml-4 mr-sm-3"
                      alt="placeholder"
                    ></b-img>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <chipinplus
      v-if="song.chipin_plus && song.locked"
      v-bind:user_id="song.artist_id"
      v-bind:username="song.username"
      v-bind:index="index"
      v-bind:stripe_type="song.made_on_stripe_account"
    ></chipinplus>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import player from "../../../components/player.vue";
import SongActions from "../../ancillary/library/song/actions.vue";
import chipinplus from "../../../components/chipinplus-indexed.vue";

export default {
  name: "SongListAsset",
  props: ["song", "queue_songs"],
  components: {
    player,
    SongActions,
    chipinplus,
  },
  data: function() {
    return {
      liked: null,
      index: null,
    };
  },
  mounted() {
    this.liked = this.song.liked;
    this.index = Math.floor(Math.random() * 100 + 1);
  },
  methods: {
    ...mapActions("Song", ["like_song", "unlike_song"]),

    likeSong() {
      this.liked = true;
      this.like_song(this.song.id);
    },

    unlikeSong() {
      this.liked = false;
      this.unlike_song(this.song.id);
    },
  },
};
</script>

<style scoped>
.first-line {
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden;
  padding-right: 5px;
  width: 96%;
}

.song-title {
  font-size: 24px;
  font-weight: 300;
}

.artist {
  font-size: 24px;
  font-weight: 300;
}

.song-description {
  font-size: 18px;
  font-weight: 300;
  text-overflow: ellipsis;
  white-space: wrap !important;
  overflow: hidden;
  height: 80px;
}

.pill-button {
  width: 37px;
  height: 21px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin-top: -5px;
}

.song-locked {
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 100;
}

.three-dots-btn {
  position: relative;
  top: -10px;
}

.c-song-listens {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}

.c-song-actions .col {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  margin-right: 12px;
  justify-content: end;
}

@media (min-width: 1200px) {
  .song-title {
    line-height: 30px;
    font-size: 24px;
    font-weight: 400;
    text-decoration: unset !important;
  }

  .artist {
    line-height: 30px;
    font-size: 24px;
    font-weight: 300;
  }

  .song-description {
    font-size: 18px;
    font-weight: 300;
  }

  .pill-button {
    width: 37px;
    height: 21px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-top: -5px;
  }

  .song-locked {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 100;
  }

  .c-song-actions {
    display: flex;
    justify-content: center;
  }

  .c-heart-btn {
    display: flex;
    width: 32px;
    height: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (max-width: 992px) {
  .svg-inline--fa.fa-w-16 {
    width: 0.8em;
  }

  .song-time-code {
    display: none;
  }

  .c-heart-btn {
    display: flex;
    width: 30px;
    height: 28px;
  }

  .listens-mobile {
    display: block;
  }

  .first-line {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
    padding-right: 5px;
  }

  .three-dots-btn {
    margin-left: 15px !important;
  }

  .three-dots-btn {
    position: relative;
    top: -2px;
  }
  .c-song-listens {
    margin-right: 2rem;
  }
}

@media (max-width: 768px) {
  .c-song-listens {
    margin-right: 1rem;
  }
  .song-title {
    font-size: 20px;
    font-weight: 300;
  }

  .artist {
    font-size: 20px;
    font-weight: 300;
  }

  .song-description {
    font-size: 18px;
    font-weight: 300;
  }

  .pill-button {
    width: 37px;
    height: 21px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-top: -5px;
  }

  .song-locked {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 100;
  }

  .first-line {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
    padding-right: 5px;
  }

  .container {
    max-width: auto !important;
  }

  .three-dots-btn {
    position: relative;
    top: -4px;
    margin-left: 20px;
    padding-left: 0;
  }

  .svg-inline--fa.fa-w-16 {
    width: 0.7em;
  }

  .reward-image {
    width: 40px;
    position: relative;
    top: -10px;
  }

  .c-heart-btn {
    margin-right: 5px;
  }

  .listens-mobile {
    position: relative;
    top: -10px;
  }

  .three-dots-btn {
    margin-left: 10px !important;
  }
}

@media (max-width: 575.98px) {
  .song-title {
    font-size: 16px;
    font-weight: 300;
    margin-left: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    width: 60px !important;
    display: inline;
  }

  .chipinplus-badge {
    height: 20px;
    margin-right: 4px !important;
  }

  .artist {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 3px !important;
  }

  .song-description {
    font-size: 14px;
    font-weight: 300;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
    height: 45px !important;
    margin-bottom: 0;
  }

  .first-line,
  .second-line {
    margin-bottom: 2px !important;
  }

  .first-line {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
    padding-right: 5px;
  }

  .explicit {
    font-size: 10px;
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    margin: 0px 5px;
  }

  .three-dots-btn {
    position: relative;
    top: -4px;
    margin-left: 3px !important;
    padding-left: 0;
  }

  .svg-inline--fa.fa-w-16 {
    width: 0.7em;
  }

  .c-heart-btn {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .pill-button {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 1px !important;
    padding-bottom: 0px !important;
    width: auto;
    height: auto;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    margin-top: 0px;
    border-radius: 6px !important;
  }

  .song-locked {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 100;
  }

  .reward-image {
    width: 40px;
    position: relative;
    top: -10px;
    margin-right: 10px;
  }

  .listens-mobile {
    position: relative;
    top: -10px;
  }

  .c-song-listens {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }
}

@media (max-width: 414px) {
  .svg-inline--fa.fa-w-16 {
    width: 0.6em;
  }

  .c-heart-btn {
    width: 27px;
    margin-left: 0.5em;
    margin-right: 0.25em;
  }
}

@media (max-width: 375px) {
  .explicit {
    margin: 0px 5px;
  }

  .c-heart-btn {
    margin-left: 20px;
    width: 23px;
    margin-right: 0.25em;
  }
}

@media (max-width: 350px) {
  .container-like-btn {
    padding-right: 0px;
  }
}

@media (max-width: 320px) {
  .svg-inline--fa.fa-w-16 {
    width: 0.6em;
  }

  .c-heart-btn {
    width: 22px;
    margin-left: 0.5em;
    margin-right: 0.25em;
  }

  .reward-image {
    width: 35px;
    position: relative;
    top: -25px;
  }

  .listens-mobile {
    position: relative;
    top: -20px;
  }

  .c-song-listens {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>

<template>
  <b-modal
    :id="'duplicate-song-modal' + id"
    size="lg"
    centered
    hide-footer
    title-sr-only
    content-class="bg-dark text-light text-center"
    header-close-variant="light"
    modal-class="dot-dot-dot-modal"
    @hide="onClose"
  >
    <template #modal-header="{ close }">
      <b-container fluid>
        <b-row class="text-center">
          <!-- Required for equal spacing -->
          <b-col></b-col>

          <b-col cols="12">
            <img
              src="../../../../assets/trackd-icon-colours.svg"
              class="mb-4 float-right mt-2"
              @click="close()"
              height="35"
              alt="Logo"
            />
            <h4 class="font-weight-lighter text-left h2 mt-5">
              Duplicate Song
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-container fluid>
      <b-row>
        <div
          class="col-11 pl-3
      "
        >
          <div v-if="responseMsg">
            <p :class="'font-weight-light ' + responseClass">
              {{ responseMsg }}
            </p>
          </div>

          <b-form @submit.stop.prevent="Submit" class="pb-2">
            <b-form-group>
              <b-form-input
                id="name"
                class="text-input"
                size="lg"
                v-model="$v.form.name.$model"
                placeholder="Name"
                :state="validateState('name')"
              ></b-form-input>
              <b-form-invalid-feedback class="text-left" id="name-feedback"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group>
              <b-button
                type="submit"
                variant="info"
                class="px-4 py-2 mt-3 btn-rounded float-right text-uppercase"
                >Duplicate</b-button
              >

              <b-button
                @click="$bvModal.hide('duplicate-song-modal' + id)"
                variant="secondary"
                size="md"
                class="px-4 py-2  mt-3 float-left btn-rounded text-uppercase d-inline-block d-md-none"
                >Close</b-button
              >
            </b-form-group>
          </b-form>
        </div>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "DuplicateSong",
  props: ["id"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: null,
      },
      showAPIError: false,
      APIErrorText: null,
      responseMsg: null,
      responseClass: null,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("Song", ["duplicate_song"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onClose() {
      this.showAPIError = false;
      this.APIErrorText = null;
      this.responseMsg = null;
      this.responseClass = null;
    },
    Submit() {
      this.showAPIError = false;
      this.responseMsg = null;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.duplicate_song([this.id, this.form])
        .then((res) => {
          res;

          this.$v.form.$reset();
          this.responseMsg = res.data.status;
          this.responseClass = "text-success";

          this.form.name = "";

          setTimeout(() => {
            this.$bvModal.hide('duplicate-song-modal' + this.id);
            this.$router.push({
              name: "Song",
              params: { id: res.data.id },
            });
          }, 2000);
        })
        .catch((error) => {

          if (error.data.errors) {
            this.responseMsg = error.data.errors[0];
            this.responseClass = "text-danger";
          } else if (error.data.non_field_errors) {
            this.responseMsg = error.data.non_field_errors[0];
            this.responseClass = "text-danger";
          } else {
            this.responseMsg = error.data.message;
            this.responseClass = "text-danger";
          }
        });
    },
  },
  mounted() {},
};
</script>

<style>
.forget-link {
  text-decoration: none;
}
.rect-box {
  border-radius: 0 !important;
}

.text-underline {
  text-decoration: underline;
}
.text-underline:hover {
  text-decoration: none;
}

.btn-rounded {
  border-radius: 10px;
}

.text-input {
  background-color: #222222;
  color: #9b9b9b;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 300;
}

.form-control:focus {
  background-color: #222222;
  color: #9b9b9b;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #9b9b9b;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #9b9b9b;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #9b9b9b;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #9b9b9b;
}

.b-dropdown-form:focus {
  outline: none !important;
}
</style>
